import OktaSignIn from '@okta/okta-signin-widget'

let sessionExpired = Boolean('')

const signIn = new OktaSignIn({
  baseUrl: window.config.oktaUrl,
  customButtons: [],
  logo: `/public/images/maxwell-vert.png`,
  i18n: {
    en: {
      'primaryauth.title': 'Log in as Internal Admin',
      'primaryauth.submit': 'LOG IN',
      'error.username.required': 'Please enter an email',
      'password.forgot.email.or.username.tooltip': 'Please enter your email',
      'primaryauth.username.placeholder': 'Email',
      'enroll.choices.title': 'Set up two-step verification',
      'enroll.choices.setup': 'Set up',
      'enroll.sms.setup':
        "Enter your mobile phone number and we'll send you a text message with a simple six-digit code.",
      'enroll.call.setup': "Enter your phone number and we'll call you and read out a simple six-digit code.",
      'enroll.choices.description.generic':
        'Maxwell wants to help keep your account secure using two-step verification. Choose how you want to receive the security code below.',
      'enroll.totp.title': 'Set up {0}',
      'enroll.totp.setupGoogleAuthApp': 'Launch the {0}, tap the ( + ) icon, then scan this barcode.',
      'enroll.totp.enterCode': 'Enter the six-digit code displayed in the app.',
      'enroll.totp.downloadApp':
        'Use your smartphone to download the <a href="{0}" class="inline-link">{1} from the {2}</a>.',
      'factor.totpSoft.googleAuthenticator': 'Google Authenticator App',
      'factor.totpSoft.description': 'Download an app for your Android or Apple phone to generate the code.',
      'factor.sms': 'SMS / Text Message',
      'factor.sms.description': 'Receive a text message on your mobile phone.',
      'factor.call': 'Voice Call',
      'factor.call.description': 'Receive an automated phone call with a voice prompt.',
      'mfa.backToFactors': 'Choose other method',
      'mfa.scanBarcode': ' ',
      iphone: 'Apple iPhone',
    },
  },
  helpLinks: {
    forgotPassword: '',
    custom: [],
  },
})

signIn.renderEl({ el: '#login' }, async function success(okta) {
  if (okta.status === 'SUCCESS') {
    const requestBody = {
      email: okta.user.profile?.login || '',
      token: okta.session.token,
    }

    let response = {}
    try {
      const rawResponse = await window.fetch('/api/v1/p/auth/login', {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })
      response = await rawResponse.json()
    } catch (err) {
      // TODO: handle internal error and okta authorize error
    }

    if (response.success) {
      window.location.replace('/')
    } else {
      const errorStr = response.error || 'Error authenticating'
      const errorContainer = document.querySelector('.o-form-error-container')
      const errorMessageDiv = document.createElement('div')

      errorContainer.replaceChildren(errorMessageDiv)
      errorContainer.className = errorContainer.className + ' o-form-has-errors'
      errorMessageDiv.className = 'okta-form-infobox-error infobox infobox-error'
      errorMessageDiv.setAttribute('role', 'alert')
      errorMessageDiv.innerHTML = `<span class="icon error-16"></span><p>${errorStr}</p>`
    }
  } else {
    // TODO: handle states outside of SUCCESS/PASSWORD_EXPIRE/MFA_ENROLL/MFA_REQUIRED
  }
})

signIn.on('afterRender', function (context) {
  document.querySelector('a.help.js-help')?.remove()
  document.querySelector('.help-links a.js-help-link')?.remove()
  // remove inline display none
  document.querySelector('.help-links')?.removeAttribute('style')

  if (document.querySelector('.forgot-password')) {
    const forgotPasswordMessage = document.createElement('div')
    forgotPasswordMessage.innerHTML = `<p>
        <strong>Only internal administrators can reset passwords for your role.</strong>
    </p>
    <br />
    <p>
        To trigger a password reset email to be sent to your email address,
        please reach out to a Maxwell Health internal administrator or
        <a href="mailto:support@maxwellhealth.com">email our support team</a>.
    </p>`
    document.querySelector('.forgot-password .o-form-fieldset-container').replaceWith(forgotPasswordMessage)
    document.querySelector('#login #okta-sign-in.auth-container .auth-content-inner').removeAttribute('style')
  }

  document.querySelector('a.js-forgot-password')?.addEventListener('click', () => {
    document.querySelector('#login #okta-sign-in.auth-container .auth-content-inner').style.opacity = 0
  })

  if (sessionExpired) {
    const sessionExpiredMessage = document.createElement('div')
    const errorContainer = document.querySelector('.o-form-error-container')

    errorContainer.parentNode.insertBefore(sessionExpiredMessage, errorContainer)
    sessionExpiredMessage.className = 'expired'
    sessionExpiredMessage.textContent = `You have been signed out due to inactivity.`

    sessionExpired = false
  }
})
